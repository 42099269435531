import React from "react";
import BrandedOptions from "./components/Branded-Options/BrandedOptions";
import Carousels from "./components/Carousel/Carousels";
import FormSec from "./components/Form/FormSec";
import Handee from "./components/Handee/Handee";
import HandeeAlt from "./components/HandeeAlt/HandeeAlt";
import LitreHand from "./components/LitreHand/LitreHand";
import SmartChat from "./components/SmartChat/SmartChat";
import TouchlessMachine from "./components/Touchless/TouchlessMachine";

import Footer from "./Footer/Footer";
import Navigationbar from "./Menubar/Navigationbar";

function Home() {
  return (
    <>
      <Navigationbar />
      <HandeeAlt />
      <Carousels />
      <SmartChat />
      <TouchlessMachine />
      <BrandedOptions />
      <LitreHand />
      <Handee />
      <FormSec />
      <Footer />
    </>
  );
}

export default Home;
