import React from "react";
import { Container, Row } from "react-bootstrap";
import Images from "../Image/Image";
import Slider from "react-slick";
import "./BrandedOptions.css";

function BrandedOptions() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="brand_all_images">
      <Container>
        <Row>
          <div>
            <p className="branded_options_text">Branded options available</p>
            <p className="branded_options_dis ">
              The Handee Machine can be applied with printed magnets thus
              allowing our customers the freedom of having custom looking
              machine.{" "}
            </p>
          </div>
        </Row>

        <Row className="m-0">
          <Slider {...settings}>
            <div className="all_img">
              <Images
                src={"Handee_blank.png"}
                srcWebp={"Handee_blank.webp"}
                width="172"
                height="472"
              />
            </div>
            <div className="all_img">
              <Images
                src={"Handee_Clicks.png"}
                srcWebp={"Handee_Clicks.webp"}
                width="172"
                height="472"
              />
            </div>
            <div className="all_img">
              <Images
                src={"Handee_Handee.png"}
                srcWebp={"Handee_Handee.webp"}
                width="172"
                height="472"
              />
            </div>
            <div className="all_img">
              <Images
                src={"Handee_OK.png"}
                srcWebp={"Handee_OK.webp"}
                width="172"
                height="472"
              />
            </div>
            <div className="all_img">
              <Images
                src={"Handee_PnP.png"}
                srcWebp={"Handee_PnP.webp"}
                width="172"
                height="472"
              />
            </div>
            <div className="all_img">
              <Images
                src={"Handee_Redefine.png"}
                srcWebp={"Handee_Redefine.webp"}
                width="172"
                height="472"
              />
            </div>
            <div className="all_img">
              <Images
                src={"Handee_Spar.png"}
                srcWebp={"Handee_Spar.webp"}
                width="172"
                height="472"
              />
            </div>
            <div className="all_img">
              <Images
                src={"Handee_VA.png"}
                srcWebp={"Handee_VA.webp"}
                width="172"
                height="472"
              />
            </div>
          </Slider>
        </Row>
      </Container>
    </div>
  );
}

export default BrandedOptions;
