import React from "react";
import { Row, Col } from "react-bootstrap";

function RightSideContentForm(props) {

  return (
    <>
      <div className="price_item_main">
        <div className="price_item">
          <p className="pb-2 your_price_text mt-0">Your Price</p>

          <Row className="gx-lg-3 gx-2">
            <Col lg={8} xs={6} className="ps-0">
              <span className="yes_text mb-0 mt-md-2">
                Item<br></br>
              </span>
            </Col>
            <Col lg={2} xs={3}>
              <p className="yes_text mb-0 mt-md-2">Qty</p>
            </Col>
            <Col lg={2} xs={3} className="text-lg-start text-end">
              <p className="yes_text mb-0 mt-md-2">Cost</p>
            </Col>
            <hr className="mt-3"></hr>
          </Row>

          {props.selectmachine === "Rent" && (
            <Row className="gx-lg-3 gx-2">
              <Col lg={8} xs={6} className="ps-0">
                <span className="machine_rental_text">
                  Machine<br></br>
                </span>
                <ul className="Only_Machine_innner_text ps-3 mb-0 text-nowrap">
                  <li>Rental per month</li>
                </ul>
              </Col>
              <Col lg={2} xs={3}>
                <p className="yes_text mb-0 mt-md-2">{props.requiremachine}</p>
              </Col>
              <Col lg={2} xs={3} className="text-lg-start text-end">
                <p className="yes_text mb-0 mt-md-2">R{props.requiremachine * 250}.00</p>
              </Col>
              <hr className="mt-3"></hr>
            </Row>
          )}

          {props.selectmachine === "Buy" && (
            <Row className="gx-lg-3 gx-2">
              <Col lg={8} xs={6} className="ps-0">
                <span className="machine_rental_text">
                  Buy Machine<br></br>
                </span>
              </Col>
              <Col lg={2} xs={3}>
                <p className="yes_text mb-0 mt-md-2">{props.requiremachine}</p>
              </Col>
              <Col lg={2} xs={3} className="text-lg-start text-end">
                <p className="yes_text mb-0 mt-md-2">R{props.requiremachine * 4000}.00</p>
              </Col>
              <hr className="mt-3"></hr>
            </Row>
          )}

          {props.brandedmagnets && props.brandedmagnets === "Yes" && (
            <Row className="gx-lg-3 gx-2">
              <Col lg={8} xs={6} className="ps-0">
                <span className="machine_rental_text">
                  Branded Magnets<br></br>
                </span>
                <ul className="Only_Machine_innner_text ps-3 mb-0 text-nowrap">
                  <li>3X Printed Magnets</li>
                </ul>
              </Col>
              <Col lg={2} xs={3}>
                <p className="yes_text mb-0 mt-md-2">{props.requiremachine}</p>
              </Col>
              <Col lg={2} xs={3} className="text-lg-start text-end">
                <p className="yes_text mb-0 mt-md-2">R{(props.requiremachine) * (500)}.00</p>
              </Col>
              <hr className="mt-3"></hr>
            </Row>
          )}

          {(props.selectmachine === "Rent" || props.selectmachine === "Buy") && (
            <Row className="gx-lg-3 gx-2">
              <Col lg={8} xs={6} className="ps-0">
                <span className="machine_rental_text">
                  Delivery<br></br>
                </span>
                <ul className="Only_Machine_innner_text list-unstyled mb-0 text-nowrap">
                  <li>* Estimated cost based on location</li>
                </ul>
              </Col>
              <Col lg={2} xs={3}>
                <p className="yes_text mb-0 mt-md-2">Yes</p>
              </Col>
              <Col lg={2} xs={3} className="text-lg-start text-end">
                <p className="yes_text mb-0 mt-md-2">R350.00</p>
              </Col>
              <hr className="mt-3"></hr>
            </Row>
          )}

          {props.rechargablebattery && props.rechargablebattery === "Yes" && (
            <Row className="gx-lg-3 gx-2">
              <Col lg={8} xs={6} className="ps-0">
                <span className="machine_rental_text">
                  Rechargable Battery<br></br>
                </span>
                <ul className="Only_Machine_innner_text list-unstyled mb-0 text-nowrap">
                  <li>* 220 Volt electric plug point required</li>
                </ul>
              </Col>
              <Col lg={2} xs={3}>
                <p className="yes_text mb-0 mt-md-2">{props.requiremachine}</p>
              </Col>
              <Col lg={2} xs={3} className="text-lg-start text-end">
                <p className="yes_text mb-0 mt-md-2">R{(props.requiremachine) * (300)}.00</p>
              </Col>
              <hr className="mt-3"></hr>
            </Row>
          )}

          {(props.selectmachine === "Rent" || props.selectmachine === "Buy") && (
            <Row className="gx-lg-3 gx-2">
              <Col lg={8} xs={6} className="ps-0">
                <span className="machine_rental_text">
                  Handee Sanitiser<br></br>
                </span>
              </Col>
              <Col lg={2} xs={3}>
                <p className="yes_text mb-0 mt-md-2">{(props.requiremachine + "x" + props.litres)}</p>
              </Col>
              <Col lg={2} xs={3} className="text-lg-start text-end">
                <p className="yes_text mb-0 mt-md-2">R{parseFloat(props.requiremachine) * parseFloat(props.price)}.00</p>
              </Col>
              <hr className="mt-3"></hr>
            </Row>
          )}

          {(props.selectmachine === "Rent" || props.selectmachine === "Buy") && (
            <Row className="gx-lg-3 gx-2">
              <Col lg={12} className="ps-0">
                <span className="machine_rental_text">
                  Terms<br></br>
                </span>
              </Col>
              <Col lg={12}>
                <ul className="Only_Machine_innner_text ps-3 mb-0 text-nowrap">
                  <li>R500 refundable deposit</li>
                  <li>Minimum 12 months.</li>
                  <li>Maintenance included</li>
                </ul>
              </Col>
              <hr className="mt-3"></hr>
            </Row>
          )}

          {/* for rent */}
          {props.selectmachine === "Rent" && (
            <Row className="gx-lg-3 gx-2">
              <Col lg={12} className="ps-0">
                {
                  props.selectmachine === "Rent" ?
                    <p className="r325_per_month_text mb-0">{props.requiremachine * 250}.00
                      <span className="delivery_deposit_text">per month</span>
                    </p>
                    : null
                }
                <p className="r800_once_text mb-0">R{parseFloat(props.finaltotal)}.00
                  <span className="delivery_deposit_text">once off</span>
                </p>
                <p className="all_prices_exl_text">*All prices exclude VAT</p>
              </Col>
            </Row>
          )}

          {/* for buy */}
          {props.selectmachine === "Buy" && (
            <Row className="gx-lg-3 gx-2">
              <Col lg={12} className="ps-0">
                <p className="r800_once_text mb-0">R{parseFloat(props.finaltotal)}.00
                  < span className="delivery_deposit_text">once off</span>
                </p>
                <p className="all_prices_exl_text">*All prices exclude VAT</p>
              </Col>
            </Row>
          )}

        </div>
      </div>
    </>
  );
}

export default RightSideContentForm;