import React from "react";
import "../Handee/Handee.css";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import Images from "../Image/Image";

function Handee() {
  var settings = {
    dots: false,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <section id="slder" className="hero-section">
      <Container>
        <Row>
          <div className="text-center">
            <span className="we_have_text">Happy customers</span>
            <p className="we_have_dis">
              We provide service to customers country wide
            </p>
          </div>
        </Row>
        <Row className="hero-container">
          <Col lg={12} className="hero-img">
            <div className="sanitiser_img">
              <Slider {...settings}>
                <Images
                  src={"handee_1.jpg"}
                  srcWebp={"handee_1.webp"}
                  className="img-fluid"
                  width="663"
                  height="680"
                />

                <Images
                  src={"handee_2.jpg"}
                  srcWebp={"handee_2.webp"}
                  className="img-fluid"
                  width="663"
                  height="680"
                />

                <Images
                  src={"handee_3.jpg"}
                  srcWebp={"handee_3.webp"}
                  className="img-fluid"
                  width="663"
                  height="680"
                />

                <Images
                  src={"handee_4.jpg"}
                  srcWebp={"handee_4.webp"}
                  className="img-fluid"
                  width="663"
                  height="680"
                />

                <Images
                  src={"handee_5.jpg"}
                  srcWebp={"handee_5.webp"}
                  className="img-fluid"
                  width="663"
                  height="680"
                />
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Handee;
