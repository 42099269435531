import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import "../Form/FormSec.css";
import LeftSideContentForm from "./LeftSideContentForm";

function FormSec() {
  // useState 
  const [rentmachine, setRentmachine] = useState(null);
  const [brandedmagnets, setBrandedmagnets] = useState(false);
  const [rechargablebattery, setRechargablebattery] = useState(false);
  const [litres, setlitres] = useState("0 litres");
  const [requiremachine, setRequiremachine] = useState("");
  const [selectmachine, setSelectmachine] = useState(null);
  const [handeesanitiserprice, setHandeesanitiserprice] = useState(0)
  const [barndedmanetsprice, setBarndedmanetsprice] = useState(0)
  const [deliveryprice] = useState(350)
  const [rechargable, setRechargable] = useState(0)
  const [finaltotal, setFinaltotal] = useState(0)
  const [price, setPrice] = useState(0)

  const dropdownprice = litres === "5 litres" ? 200 : (litres === "10 litres" ? 400 : (litres === "15 litres" ? 600 : (litres === "20 litres" ? 800 : 0)))
  const totalprice = (parseFloat(requiremachine * (selectmachine === "Rent" ? 250 : 4000)) + parseFloat(handeesanitiserprice) + parseFloat(barndedmanetsprice) + parseFloat(deliveryprice) + parseFloat(rechargable)).toFixed(2)

  // useEffect
  useEffect(() => {
    setHandeesanitiserprice(parseFloat(dropdownprice * requiremachine).toFixed(2));
    setBarndedmanetsprice(brandedmagnets && brandedmagnets === "Yes" ? (500 * requiremachine) : 0)
    setRechargable(rechargablebattery && rechargablebattery === "Yes" ? (300 * requiremachine) : 0)
    setFinaltotal(totalprice ? totalprice : 0)
    setPrice(dropdownprice)
  })


  // handler function
  const handler = (litres) => {
    setlitres(litres);
  };

  // For clear field after submit
  const resetLeftSecForm = () => {
    setRentmachine(null)
    setBrandedmagnets("")
    setRechargablebattery("")
    setlitres("0 litres")
    setSelectmachine("")
    setRequiremachine("")
  }

  // This is second method ...
  // const OnbrandedmagnetsChanged = (e) => {
  //   console.log(e);
  //   setBrandedmagnets(e.target.checked);
  // };

  return (
    <Container id="machineoptions">
      <div className="form">
        <Row>
          <div className="text-center">
            <span className="we_have_text">We've got machine options</span>
            <p className="we_have_dis">
              Please fill out the form below to enquire about buying or renting
              a Handee machine
            </p>
          </div>
        </Row>
        <div className="form_inners">
          <Row>
            <LeftSideContentForm
              resetLeftSecForm={resetLeftSecForm}
              rentmachine={rentmachine}
              brandedmagnets={brandedmagnets}
              rechargablebattery={rechargablebattery}
              setRentmachine={setRentmachine}
              setBrandedmagnets={setBrandedmagnets}
              setRechargablebattery={setRechargablebattery}
              setlitres={setlitres}
              handler={handler}
              litres={litres}
              requiremachine={requiremachine}
              setRequiremachine={setRequiremachine}
              selectmachine={selectmachine}
              setSelectmachine={setSelectmachine}
              handeesanitiserprice={handeesanitiserprice}
              setHandeesanitiserprice={setHandeesanitiserprice}
              finaltotal={finaltotal}
              setFinaltotal={setFinaltotal}
              price={price}
            />
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default FormSec;



