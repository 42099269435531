import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import RightSideContentForm from "./RightSideContentForm";
import FormDetails from "./FormDetails";
import { useForm } from "react-hook-form";

function LeftSideContentForm(props) {

  // useState
  const [flag, setflag] = useState(false)

  // react-hook-form state
  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors }
  } = useForm({
    mode: "onBlur"
  });

  // onsubmit function
  const selectonSubmit = () => {
    // alert(JSON.stringify(Machinevalues, null, 2));
    setflag(true)
    trigger()
  };

  // resetleft section form
  const resetLeftSecForm = () => {
    setflag(false)
    props.resetLeftSecForm()
  }

  // All field values
  const Machinevalues = getValues([
    'Machine',
    'Require',
    'Magnets',
    'Rechargable',
    'Sanitiser',
  ]);

  return (
    <>
      <Col lg={12} className="you_can_text_main">
        <Row>
          <Col lg={12}>
            <div className="form_inner">
              <Row>
                <Col xs={12} xl={6} lg={6}>
                  <h5 className="your_price_text">Handee Machine</h5>
                  <p className="you_can_text">Please fill out the form below to get a cost estimate</p>

                  <form onSubmit={handleSubmit()}>
                    <Form.Group controlId="formBasicCheckbox">
                      <div className="main_text mt-5">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <span className="how_many_text para_text_size pe-lg-0 pe-2">Do you want to Rent or Buy the Machine?</span>

                          <div className="litres_5_dropdown rounded">
                            <Form.Select
                              {...register('Machine', { required: "Machine is Required" })}
                              value={props.selectmachine}
                              onChange={(e) => props.setSelectmachine(e.target.value)}
                              className="form-select border-0 h-100">
                              <option value="">Select</option>
                              <option value="Rent">Rent</option>
                              <option value="Buy">Buy</option>
                            </Form.Select>
                          </div>
                        </div>
                        {errors.Machine && <p className="leftside-sec-error-msg">{errors.Machine.message}</p>}

                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <span className="how_many_text para_text_size pe-lg-0 pe-2">How many machines to do you require?</span>
                          <div className="litres_5_dropdown rounded">
                            <Form.Control
                              {...register('Require', { required: "Enter Require machine" })}
                              type="number" min="0" placeholder="0" value={props.requiremachine} onChange={(e) => props.setRequiremachine(e.target.value)} className="h-100" />
                          </div>
                        </div>
                        {(flag && (!props.requiremachine || props.requiremachine <= 0)) ? <p className="leftside-sec-error-msg">Enter Require machine</p> : ""}

                        <h5 className="your_price_text mb-4">Add Extra's</h5>

                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <span className="how_many_text para_text_size pe-lg-0 pe-2">I would like custom printed magnets
                            <span className="how_many_inner_text d-block">(3X Printed Magnets)</span>
                          </span>
                          <div className="litres_5_dropdown rounded">
                            <Form.Select
                              {...register('Magnets', { required: "Magnets is Required" })}
                              onChange={(e) => props.setBrandedmagnets(e.target.value)}
                              value={props.brandedmagnets}
                              className="form-select border-0 h-100">
                              <option value="" seleted>Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </div>
                        </div>
                        {errors.Magnets && <p className="leftside-sec-error-msg">{errors.Magnets.message}</p>}

                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <span className="how_many_text para_text_size pe-lg-0 pe-2">I want a rechargable battery
                            <span className="how_many_inner_text d-block">(R300 Extra)</span>
                          </span>
                          <div className="litres_5_dropdown rounded">
                            <Form.Select
                              {...register('Rechargable', { required: "Rechargable  is Required" })}
                              onChange={(e) => props.setRechargablebattery(e.target.value)}
                              value={props.rechargablebattery}
                              className="form-select border-0 h-100">
                              <option value="" seleted>Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Form.Select>
                          </div>
                        </div>
                        {errors.Rechargable && <p className="leftside-sec-error-msg">{errors.Rechargable.message}</p>}

                        <div className="d-flex justify-content-between align-items-center">
                          <div className="how_many_text para_text_size pe-lg-0 pe-2">
                            How many handee sanitiser
                            <span className="how_many_inner_text d-block">(R200 / 5 litres)</span>
                          </div>

                          <div className="litres_5_dropdown rounded">
                            <Form.Select
                              {...register('Sanitiser', { required: "Sanitiser  is Required" })}
                              className="border-0 h-100"
                              onChange={(e) => props.handler(e.target.value)}
                              value={props.litres}
                            >
                              <option value="">0 litres</option>
                              <option value="5 litres">5 litres</option>
                              <option value="10 litres">10 litres</option>
                              <option value="15 litres">15 litres</option>
                              <option value="20 litres">20 litres</option>
                            </Form.Select>
                          </div>
                        </div>
                        {errors.Sanitiser && <p className="leftside-sec-error-msg-dropdown">{errors.Sanitiser.message}</p>}

                      </div>
                    </Form.Group>
                  </form>
                </Col>

                <Col xl={6} xs={12} lg={6}>
                  <RightSideContentForm
                    rentmachine={props.rentmachine}
                    brandedmagnets={props.brandedmagnets}
                    rechargablebattery={props.rechargablebattery}
                    litres={props.litres}
                    requiremachine={props.requiremachine}
                    setRequiremachine={props.setRequiremachine}
                    selectmachine={props.selectmachine}
                    setSelectmachine={props.setSelectmachine}
                    handeesanitiserprice={props.handeesanitiserprice}
                    setHandeesanitiserprice={props.setHandeesanitiserprice}
                    finaltotal={props.finaltotal}
                    setFinaltotal={props.setFinaltotal}
                    price={props.price}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <FormDetails finaltotal={props.finaltotal} handeesanitiserprice={props.handeesanitiserprice} litres={props.litres} brandedmagnets={props.brandedmagnets} rechargablebattery={props.rechargablebattery} requiremachine={props.requiremachine} selectmachine={props.selectmachine} selectonSubmit={selectonSubmit} Machinevalues={Machinevalues} resetLeftSecForm={resetLeftSecForm} price={props.price} />
      </Col >
    </>
  );
}

export default LeftSideContentForm;

