import React from "react";
import { Col, Row } from "react-bootstrap";
import "./LitreHand.css";
import Images from "../Image/Image";
function LitreHand() {
  return (
    <section id="sanitiser" className="handee-section">
      <Row className="g-0">
        <Col xxl={5} lg={6}>
          <div className="handee-info">
            <div className="handee-container">
              <h2>5 litre Handee liquid sanitiser</h2>

              <ul>
                <li>5 litre bottle </li>
                <li>Gentle on the skin</li>
                <li>70 % alcohol-based</li>
                <li>Pleasant Aroma</li>
              </ul>
              <h3 className="handee-price">R200</h3>

              <p>*all prices exl vat </p>
            </div>
          </div>
        </Col>

        <Col xxl={7} lg={6}>
          <div className="handee-img">
            <Images
              className="img-fluid"
              src={"Hand.png"}
              srcWebp={"Hand.webp"}
              width="886"
              height="579"
            />
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default LitreHand;
