import React from "react";
import "../HandeeAlt/HandeeAlt.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-scroll";
import Video from "../Video/Video";

function HandeeAlt() {
  return (
    <section id="home" className="hero-section">
      <Container>
        <Row className="g-0  align-items-center">
          <Col lg={5}>
            <div className="hero-info">
              <div className="hero-info-container">
                <h1>Handee is Smarter</h1>
                <p>
                  Handee creates touchless hand sanitiser machines designed for
                  a better user experience. Our machines provide a state of the
                  art solution for any commercial, retail or corporate
                  environment.
                </p>
                <Link
                  to="machineoptions"
                  spy={true}
                  smooth={true}
                  duration={0}
                  offset={-200}
                  className="btn btn-primary"
                >
                  Order now
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <Video />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default HandeeAlt;
