import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../SmartChat/SmartChat.css";
import { Link } from "react-scroll";
import Images from "../Image/Image";
function SmartChat() {
  return (
    <div id="smart-chat" className="iot-section">
      <Container>
        <Row className="g-0 flex-column-reverse flex-lg-row">
          <Col lg={6} className="iot-img">
            <Images
              className="img-fluid"
              src={"hand_chat.png"}
              srcWebp={"hand_chat.webp"}
              width="580"
              height="755"
            />
          </Col>

          <Col lg={6}>
            <div className="iot-info">
              <div className="iot-container">
                <h2>Smart Chat</h2>
                <p>
                  The Handee WhatsApp bot allows our customers to schedule
                  maintenance call outs with ease.
                </p>

                <Link
                  to="machineoptions"
                  spy={true}
                  smooth={true}
                  duration={0}
                  offset={-100}
                  className="btn btn-primary"
                >
                  Order now
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SmartChat;
