import React from 'react'
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from 'aws-amplify';

// initialValues define
const initialValues = {
    Yourname: "",
    Companyname: "",
    Physicaladdress: "",
    Emailaddress: "",
    TelNumber: "",
    Message: ""
};

function FormDetails(props) {

    // onDubmit function
    const onSubmit = async (values, { resetForm }) => {
        // let finalobj = JSON.stringify(values, null, 2);

        // Email function
        const GetRequiredCost = () => {
            if (props.selectmachine === "Rent") {
                return (
                    ConvertPriceValue(props.requiremachine * 250)
                )
            }
            else {
                return (
                    ConvertPriceValue(props.requiremachine * 4000)
                )
            }
        }

        // ConvertPrice function
        const ConvertPriceValue = (value) => {
            return (
                ("R") + (parseFloat(value).toFixed(2))
            )
        }

        // 1.1 - show-hide permonth in toatl price function
        const PerMonthInTotatal = () => {
            if (props.selectmachine === "Rent") {
                return (
                    ConvertPriceValue(props.requiremachine * 250)
                )
            }
        }

        let finalobj = {
            ...values,

            buy_rent: props.selectmachine,
            machine_qty: props.requiremachine,
            buy_rent_cost: GetRequiredCost(),
            buy_rent_note: "",

            Branded_Magnets_purchased: "Branded Magnets",
            Branded_Magnets_qty: props.requiremachine,
            Branded_Magnets_cost: ConvertPriceValue(props.requiremachine * 500),
            Branded_Magnets_note: "3X Printed Magnets",
            Branded_Magnets_Select_Value: props.brandedmagnets,

            Rechargable_Battery_purchased: "Rechargable Battery",
            Rechargable_Battery_note: "220 Volt electric plug point required",
            Rechargable_Battery_qty: props.requiremachine,
            Rechargable_Battery_cost: ConvertPriceValue(props.requiremachine * 300),
            Rechargable_Battery_Select_Value: props.rechargablebattery,

            Handee_Sanitiser_purchased: "Handee Sanitiser",
            Handee_Sanitiser_note: "",
            Handee_Sanitiser_qty: (props.requiremachine + " x " + props.litres),
            Handee_Sanitiser_cost: ConvertPriceValue(props.requiremachine * props.price),

            Delivery_note: "Estimated cost based on location",
            Delivery_cost: "R350.00",

            onceoff: ConvertPriceValue(props.finaltotal),
            perMonth: PerMonthInTotatal(),
        }

        console.log(finalobj);

        const apiName = 'mainappapi';
        const path1 = '/sendEmailUser';
        const path2 = '/sendEmailAdmin';
        const myInit = {
            headers: { 'Content-Type': 'application/json' },
            body: finalobj
        };

        await API.post(apiName, path1, myInit);

        await API.post(apiName, path2, myInit);

        // 1.2 -when select buy - (permonth) gonna hide!
        var PerMonthInTotatalval = PerMonthInTotatal()
        if (PerMonthInTotatalval !== undefined) {
            finalobj.permonth = PerMonthInTotatalval
        }


        // This condition is for Reset the form after submit
        if (props.litres !== "0 litres" && (props.brandedmagnets !== "") && (props.rechargablebattery !== "") && props.requiremachine !== "" && props.selectmachine !== null) {
            diffToast();
            resetForm();
            props.resetLeftSecForm()
        }
    };

    // validate function
    const validate = (values) => {
        const errors = {};
        if (!values.Physicaladdress) {
            errors.Physicaladdress = "Physical address Field is Required!";
        }
        if (!values.Yourname) {
            errors.Yourname = "Your Name Field is Required!";
        }
        if (!values.Companyname) {
            errors.Companyname = "Company Name Field is Required!";
        }
        if (!values.Emailaddress) {
            errors.Emailaddress = "Email Address Field is Required!";
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Emailaddress)
        ) {
            errors.Emailaddress = "Invalid email address";
        }
        if (!values.TelNumber) {
            errors.TelNumber = "Tel Number Field is Required!";
        }
        return errors;
    };

    // Toast function
    const diffToast = () => {
        toast.success("your form submit successfully", {
            position: "top-center"
        })
    }

    // Formik values
    const formik = useFormik({
        initialValues,
        onSubmit,
        validate,
    });

    return (
        <>
            <Row>
                <Col lg={11} className="mx-auto">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="main_delivery_text">
                            <div className="main_form">
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label
                                                className="form_text para_text_size pe-lg-0 pe-2"
                                                htmlFor="Yourname"
                                            >
                                                Name & Surname
                                            </Form.Label>
                                            <Form.Control
                                                className="from_control_lable"
                                                type="text"
                                                id="text"
                                                name="Yourname"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Yourname}
                                            />
                                            {formik.touched.Yourname && formik.errors.Yourname ? (
                                                <div className="error">{formik.errors.Yourname}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label
                                                className="form_text para_text_size pe-lg-0 pe-2"
                                                htmlFor="Companyname"
                                            >
                                                Company Name
                                            </Form.Label>
                                            <Form.Control
                                                className="from_control_lable"
                                                type="tel"
                                                id="text"
                                                name="Companyname"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Companyname}
                                            />
                                            {formik.touched.Companyname && formik.errors.Companyname ? (
                                                <div className="error">{formik.errors.Companyname}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label
                                                className="form_text para_text_size pe-lg-0 pe-2"
                                                htmlFor="Physicaladdress"
                                            >
                                                Your Physical Address
                                            </Form.Label>
                                            <Form.Control
                                                className="from_control_lable"
                                                type="text"
                                                id="text"
                                                name="Physicaladdress"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Physicaladdress}
                                            />
                                            {formik.touched.Physicaladdress &&
                                                formik.errors.Physicaladdress ? (
                                                <div className="error">
                                                    {formik.errors.Physicaladdress}
                                                </div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mb-4">
                                            <Form.Label
                                                className="form_text para_text_size pe-lg-0 pe-2"
                                                htmlFor="Emailaddress"
                                            >
                                                Email address
                                            </Form.Label>
                                            <Form.Control
                                                className="from_control_lable"
                                                type="email"
                                                id="email"
                                                name="Emailaddress"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.Emailaddress}
                                            />
                                            {formik.touched.Emailaddress &&
                                                formik.errors.Emailaddress ? (
                                                <div className="error">{formik.errors.Emailaddress}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="mb-4">
                                            <Form.Label
                                                className="form_text para_text_size pe-lg-0 pe-2"
                                                htmlFor="TelNumber"
                                            >
                                                Tel Number
                                            </Form.Label>
                                            <Form.Control
                                                className="from_control_lable"
                                                type="number"
                                                id="TelNumber"
                                                name="TelNumber"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.TelNumber}
                                            />
                                            {formik.touched.TelNumber &&
                                                formik.errors.TelNumber ? (
                                                <div className="error">{formik.errors.TelNumber}</div>
                                            ) : null}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={12}>
                                        <Form.Group className="mb-3">
                                            <Form.Label
                                                className="form_text para_text_size pe-lg-0 pe-2"
                                                htmlFor="Message"
                                            >
                                                Your Message * optional
                                            </Form.Label>
                                            <Form.Control
                                                className="from_control_lable"
                                                type="text"
                                                id="text"
                                                name="Message"
                                                onChange={formik.handleChange}
                                                value={formik.values.Message}
                                                as="textarea" rows={4}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col className="mx-auto mt-lg-5 mt-3" lg={5}>
                                        <Button
                                            onClick={() => props.selectonSubmit()}
                                            // oncClick={diffToast}
                                            className="btn enquire_btn w-100" type="submit">
                                            Enquire now
                                        </Button>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </form>
                </Col>
            </Row>
            <ToastContainer />
        </>
    )
}

export default FormDetails