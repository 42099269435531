import React, { useEffect, useState } from "react";
import { Nav, Navbar, Container, NavItem } from "react-bootstrap";
import "./Navigationbar.css";

import { Link } from "react-scroll";
import Images from "../components/Image/Image";

function Navigationbar() {
  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Navbar
      className={
        isScrollValueMoreThanHeaderHeight
          ? "bg-opacity mainNavbar"
          : "mainNavbar"
      }
      variant="light"
      expand="lg"
    >
      <Container>
        <Navbar.Brand>
          <Images
            src={"logo.svg"}
            srcWebp={"logo.webp"}
            className="group_logo_img img-fluid"
            width="151"
            height="75"
          />
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="offcanvasNavbar"
          className="Menu_btn"
          // aria-controls="offcanvasNavbar"
        ></Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="ms-auto main_menu">
            <NavItem className="nav_items">
              <Link
                to="home"
                spy={true}
                smooth={true}
                duration={0}
                offset={-110}
                className="text-dark nav-link"
              >
                Home
              </Link>
            </NavItem>
            <NavItem className="nav_items">
              <Link
                to="smart-chat"
                spy={true}
                smooth={true}
                duration={0}
                offset={-110}
                className="text-dark nav-link"
              >
                Smart Chat
              </Link>
            </NavItem>
            <NavItem className="nav_items">
              <Link
                to="sanitiser"
                spy={true}
                smooth={true}
                duration={0}
                offset={-110}
                className="text-dark nav-link"
              >
                Sanitiser
              </Link>
            </NavItem>
            <NavItem className="nav_items">
              <Link
                to="machineoptions"
                spy={true}
                smooth={true}
                duration={0}
                offset={-110}
                className="text-dark nav-link"
              >
                Machine Options
              </Link>
            </NavItem>
            <Link
              to="machineoptions"
              spy={true}
              smooth={true}
              duration={0}
              offset={-110}
              className="btn text-light contact_btn"
            >
              Contact us
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigationbar;
