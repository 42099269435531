import React from "react";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import "../Carousel/Carousels.css";

import Images from "../Image/Image";

function Carousels() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    autoplay: true,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <Container>
        <Row>
          <Col className="main_trust">
            <span className="trusted_by ">Trusted by</span>

            <Slider {...settings} className="client-slide-section">
              <div>
                <Images
                  className="img-fluid"
                  src={"logo-woolworths.png"}
                  srcWebp={"logo-woolworths.webp"}
                  width="225"
                  height="60"
                />
              </div>
              <div>
                <Images
                  className="img-fluid"
                  src={"logo-spar.png"}
                  srcWebp={"logo-spar.webp"}
                  width="74"
                  height="86"
                />
              </div>
              <div>
                <Images
                  className="img-fluid"
                  src={"logo-pick-n-pay.png"}
                  srcWebp={"logo-pick-n-pay.webp"}
                  width="205"
                  height="40"
                />
              </div>
              <div>
                <Images
                  className="img-fluid"
                  src={"logo-redefine.png"}
                  srcWebp={"logo-redefine.webp"}
                  width="204"
                  height="50"
                />
              </div>
              <div>
                <Images
                  className="img-fluid"
                  src={"logo-v-a.png"}
                  srcWebp={"logo-v-a.webp"}
                  width="104"
                  height="60"
                />
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Carousels;
