import React from "react";
import ReactPlayer from "react-player";

const Video = () => {
  return (
    <div className="player-wrapper rounded-4 overflow-hidden">
      <ReactPlayer
        className="react-player"
        width="100%"
        height="100%"
        playing
        loop
        muted
        playsinline
        url={[
          { src: "info.webm", type: "video/webm" },
          { src: "info.ogg", type: "video/ogg" },
          { src: "info.mp4", type: "video/mp4" },
        ]}
      />
    </div>
  );
};

export default Video;
