import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Footer/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col xl={12} className=" pt-4 pb-2">
            <p>
              Please contact us for a demonstration :{" "}
              <a className="links" href="https://handee.co.za/">
                www.handee.co.za {" "}
              </a>
              <a className="links" href="https://handee.co.za">
                | hello@handee.co.za
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
