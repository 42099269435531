import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../Touchless/TouchlessMachine.css";
import { Link } from "react-scroll";
import Images from "../Image/Image";
function TouchlessMachine() {
  return (
    <div className="Touchless_Machine_Main">
      <Container>
        <Row>
          <Col lg={4} xs={12} md={12}>
            <div>
              <h1 className="Touchless_machine_text">
                Touchless Sanitiser Machine
              </h1>
              <p className="Touchless_machine_dis_1">
                Our Handee machine delivers touchless sanitiser for both indoor
                or outdoor environments.
              </p>
              <p className="Touchless_machine_dis_2">
                The internal 5L tank has a capacity to sanitise 18,000+ hands*{" "}
              </p>
            </div>
          </Col>

          <Col lg={4} xs={12} md={12} className="text-center">
            <Images
              src={"picknpay.png"}
              srcWebp={"picknpay.webp"}
              width="172"
              height="473"
            />
          </Col>

          <Col lg={4} xs={12} md={12}>
            <div className="Handee_machine_Main">
              <p className="Handee_machine_text">
                Handee Machine Specifications
              </p>
              <ul className="Handee_machine_ul">
                <li>1.5 meters in height</li>
                <li>Ultrasonic detection sensor</li>
                <li>No mechanical or moving parts</li>
                <li>5 litre refill size (18 000 sprays)</li>
                <li>220 Volt electric plug point required</li>
                <li>
                  The Machine requires electricity to run (5m power lead
                  included)
                </li>
              </ul>
              <Link
                to="machineoptions"
                spy={true}
                smooth={true}
                duration={0}
                offset={-200}
                className="btn btn-primary mt-4"
              >
                Order now
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TouchlessMachine;
